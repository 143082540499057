<template>
  <component :is="departmentData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No Departement found -->
    <b-alert variant="danger" :show="departmentData === undefined">
      <h4 class="alert-heading">Erreur lors de chargement du contenu</h4>
      <div class="alert-body">
        Aucun element trouvé avec cet identifiant. Vérifier
        <b-link class="alert-link" :to="{ name: 'department-list' }">
          Liste des elements
        </b-link>
        pour les autres element.
      </div>
    </b-alert>

    <data-add-tab
      :department-data="departmentData"
      :moduleformblank-data="moduleformblankData"
      class="mt-2"
    />
  </component>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BRow,
  BCol,
  BAlert,
  BLink,
  BFormCheckbox,
  BButton,
} from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import storeModule from "./storeModule";
import DataAddTab from "./FormData.vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BRow,
    BCol,
    BAlert,
    BLink,
    BFormCheckbox,
    DataAddTab,
    BButton,
    // DataAddTabInformation,
    // DataAddTabSocial,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      operationTitle: "",
      operationIcon: "",
    };
  },
  mounted() {
    if (router.currentRoute.name == "department-add-new") {
      this.operationTitle = "Nouveau Departement";
      this.operationIcon = "AddIcon";
    }
    if (router.currentRoute.name == "department-view") {
      this.operationTitle = "Détails de la Departement";
      this.operationIcon = "EyeIcon";
    }
    if (router.currentRoute.name == "department-edit") {
      this.operationTitle = "Modification de la department";
      this.operationIcon = "EditIcon";
    }
  },
  methods: {
    prevPage() {
      router.push({ name: "department-list" });
    },
    convertBoolToInt(value) {
      if (value == 1) {
        return true;
      } else {
        return false;
      }
    },
  },
  setup() {
    let departmentData = ref(null);

    var now = new Date();

    var moduleformblankData = ref({
      sku_department_parent: "",
      sku_module: "module-" + now.getTime(),
      name: "",
      route: "",
      icon: "",
    });

    let APP_STORE_MODULE_NAME = "app-department";

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME))
      store.registerModule(APP_STORE_MODULE_NAME, storeModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME))
        store.unregisterModule(APP_STORE_MODULE_NAME);
    });

    var defaultdepartmentData = ref({
      // id: 5037,
      sku_department_parent: "",
      sku_department: "department-" + now.getTime(),
      name: "",
      route: "",
      icon: "",
      description: "",
      // ? Set single Item in form for adding data
      modules: [],
    });
    // JSON.parse(JSON.stringify(itemformblankData))
    if (router.currentRoute.name == "department-add-new") {
      // init empty department data
      departmentData = defaultdepartmentData;
    }

    if (router.currentRoute.name != "department-add-new") {
      // requirement
      store
        .dispatch(APP_STORE_MODULE_NAME + "/fetchModule", {
          id: router.currentRoute.params.id,
        })
        .then((response) => {
          response.data.modules = JSON.parse(response.data.modules);
          departmentData.value = response.data;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            departmentData.value = undefined;
          }
        });
    }

    return {
      departmentData,
      moduleformblankData,
    };
  },
};
</script>
